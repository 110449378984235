import React from 'react';
import './modal.css';


export function Modal(props: any) {
    const renderHeader = () => <div className="modal-header">{props.children[0]}</div>;
    const renderBody = () => <div className="modal-body">{props.children[1]}</div>;
    const renderFooter = () => props.children[2] ? <div className="modal-footer">{props.children[2]}</div> : '';

    return <div className="modal-container" onClick={event => { event.stopPropagation(); props.onOutSideClick && props.onOutsideClick(); }}>
        <div />
        <div className="modal" onClick={event => event.stopPropagation()} >
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
        </div>
        <div />
    </div>;
}
