import {convert, Instant, ZoneId} from "@js-joda/core";
import {RangeWidth} from "../dbo/RangeWidth";

class DateService {
    getBeginningOfDay(date: Date): Date {
        const instant = Instant.ofEpochMilli(date.getTime());
        const zonedDateTime = instant.atZone(ZoneId.systemDefault());
        const day = convert(zonedDateTime.toLocalDate()).toDate();
        return day;
    }

    getEndOfDay(date: Date): Date {
        const instant = Instant.ofEpochMilli(date.getTime());
        const zonedDateTime = instant.atZone(ZoneId.systemDefault());
        const day = convert(zonedDateTime.toLocalDate().plusDays(1)).toDate();
        return day;
    }

    formatDate(date: Date): string {
        const instant = Instant.ofEpochMilli(date.getTime());
        const zonedDateTime = instant.atZone(ZoneId.systemDefault());
        const localDate = zonedDateTime.toLocalDate();
        return localDate.toString();
    }

    getStartDate(endDate: Date, rangeWidth: RangeWidth) {
        return this.getBeginningOfDay(new Date(endDate.getTime() - rangeWidth.durationSec * 1000));
    }
}

const instance = new DateService();

export {instance as DateService};

