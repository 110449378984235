import {DeviceDto} from "dto/DeviceDto";
import {PlantService} from "../service/PlantService";
import {DeviceChartData, makeChartDataForLeafSensor} from "../dbo/ChartDataElement";
import {RangeWidth} from "../dbo/RangeWidth";
import {DateService} from "../service/DateService";
import {ProfileDto} from "dto/ProfileDto";
import {SensorDto} from "dto/SensorDto";
import {ProfileService} from "../service/ProfileService";
import {indexBy} from "../utils/Utils";
import {PlantDto} from "dto/PlantDto";
import {LoadingStatus} from "../dbo/LoadingStatus";


export interface DeviceAndField {
    device: DeviceDto | null,
    field: SensorDto | null
}

export function makeFieldId(device: DeviceDto, sensor: SensorDto): string {
    return "@" + device.id + "-@" + sensor.id;
}

export async function loadMissingData(pageState: DashboardPageState, selectedItems: { [key: string]: string }, rangeWidth: RangeWidth): Promise<DashboardPageState> {
    console.info("checking for missing data");
    const deviceData = {...pageState.deviceData};
    const deviceById = indexBy(pageState.devices, 'id');
    const to = DateService.getEndOfDay(pageState.selectedDate);
    const from = DateService.getBeginningOfDay(new Date(to.getTime() - rangeWidth.durationSec * 1000));
    for (let deviceId of Object.values(selectedItems)) {
        const missingData = !deviceData[deviceId];
        console.log(`Missing data for device ${deviceId}: ${missingData}`);
        console.info(`Data for ${deviceId} missing, loading`);
        if (missingData) {
            const device = deviceById[deviceId];
            if (!device) {
                // This is an inner node
                continue;
            }
            switch (device.type) {
                case "LWM":
                case "pLWM":
                case "eLWM":
                case "aLWM":
                    const data = await PlantService.getPlantData(device.plantId, from, to);
                    // FIXME: We should have this information somewhere
                    const plant = await PlantService.getById(device.plantId);
                    const profile = pageState.profilesById[plant.deviceProfileId];
                    deviceData[deviceId] = makeChartDataForLeafSensor(data, profile, false);
                    break;
                default:
                    console.log("Device type", device.type, "not handled!");

            }
        }
    }
    return {...pageState, selectedItems, deviceData};

}


export async function toggleEntry(pageState: DashboardPageState, rangeWidth: RangeWidth, item: DeviceAndField): Promise<DashboardPageState> {
    const selectedItems = {...pageState.selectedItems};
    if (item.device === null) {
        // toggle all devices
        console.log("Toggling all devices");
        const enabled = !selectedItems[ALL_ITEMS_ID];
        if (enabled) {
            selectedItems[ALL_ITEMS_ID] = ALL_ITEMS_ID;
            for (const device of pageState.devices) {
                // FIXME: We should have this information somewhere
                const plant = await PlantService.getById(device.plantId);
                const profile = await ProfileService.getProfileById(plant.deviceProfileId);
                const dataFields = profile.sensors;
                selectedItems[makeDeviceId(device)] = device.id;
                for (let field of dataFields) {
                    selectedItems[makeFieldId(device, field)] = device.id
                }
            }
        } else {
            delete selectedItems[ALL_ITEMS_ID];
            for (const device of pageState.devices) {
                // FIXME: We should have this information somewhere
                const plant = await PlantService.getById(device.plantId);
                const profile = await ProfileService.getProfileById(plant.deviceProfileId);
                const dataFields = profile.sensors;
                delete selectedItems[makeDeviceId(device)];
                for (let field of dataFields) {
                    delete selectedItems[makeFieldId(device, field)];
                }
            }
        }

    } else if (item.field === null) {
        // flag whole device
        console.log("Toggling device", item.device.description);
        const device = item.device;
        // FIXME: We should have this information somewhere
        const plant = await PlantService.getById(device.plantId);
        const profile = await ProfileService.getProfileById(plant.deviceProfileId);
        const deviceId = makeDeviceId(device);
        const enabled = !selectedItems[deviceId];
        if (enabled) {
            const dataFields = profile.sensors;
            selectedItems[deviceId] = device.id;
            for (let field of dataFields) {
                selectedItems[makeFieldId(device, field)] = device.id;
            }
        } else {
            const dataFields = profile.sensors;
            delete selectedItems[deviceId];
            for (let field of dataFields) {
                delete selectedItems[makeFieldId(device, field)];
            }

        }
    } else {
        const id = makeFieldId(item.device, item.field);
        console.log("Toggling field", id);
        if (selectedItems[id]) {
            console.log("Deselecting");
            delete selectedItems[id];
        } else {
            console.log("Selecting");
            selectedItems[id] = item.device.id;
        }
    }
    return await loadMissingData(pageState, selectedItems, rangeWidth);
}

export default interface DashboardPageState {
    devices: DeviceDto[],
    // Loaded data, for a subset of the devices above
    deviceData: { [id: string]: DeviceChartData },
    // List of the selected items. The value is the id of the device
    // that the item belongs to
    selectedItems: { [key: string]: string },
    selectedDate: Date;
    profilesById: { [id: string]: ProfileDto },
    plants: PlantDto[];
    plantsById: { [id: string]: PlantDto };
    loadingStatus: LoadingStatus;

}

export const ALL_ITEMS_ID = 'root';

export function makeDeviceId(device: DeviceDto) {
    return "@" + device.id;
}


export const DASHBOARD_PAGE_EMPTY_STATE: DashboardPageState = {
    devices: [],
    deviceData: {},
    profilesById: {},
    plants: [],
    plantsById: {},
    selectedItems: {},
    selectedDate: DateService.getBeginningOfDay(new Date()),
    loadingStatus: "pending"
};
