import React from 'react';

export interface GreenRedProps {
    on: boolean;
}

export function GreenRed(props: GreenRedProps) {
    if (props.on) {
        return <span>🟢</span>;
    } else {
        return <span>🔴</span>;
    }
}
