{
  "name": "webfrontend",
  "version": "15.13.0",
  "private": true,
  "dependencies": {
    "@fortawesome/fontawesome-free": "^5.13.1",
    "@js-joda/core": "^1.12.0",
    "@js-joda/timezone": "^2.2.0",
    "@types/node": "12.0.10",
    "@types/react": "17.0.0",
    "@types/react-dom": "17.0.0",
    "@types/react-router-dom": "^5.3.0",
    "@types/uuid": "^8.3.0",
    "browserslist": "^4.18.1",
    "buffer": "^6.0.3",
    "css-loader": "^6.5.1",
    "eslint": "^8.3.0",
    "eslint-config-react-app": "^7.0.1",
    "file-loader": "^6.2.0",
    "fs-extra": "^10.0.0",
    "identity-obj-proxy": "^3.0.0",
    "mini-css-extract-plugin": "^2.4.5",
    "ol": "^8.0.0",
    "parcel": "^2.10.0",
    "react": "^17.0.0",
    "react-app-polyfill": "^3.0.0",
    "react-dev-utils": "^12.0.1",
    "react-dom": "^17.0.0",
    "react-pdf": "^7.5.1",
    "react-refresh": "^0.11.0",
    "react-router-dom": "^6.3.0",
    "recharts": "^2.1.9",
    "resolve": "^1.20.0",
    "resolve-url-loader": "^4.0.0",
    "sass-loader": "^12.3.0",
    "semver": "^7.3.5",
    "source-map-loader": "^3.0.0",
    "style-loader": "^3.3.1",
    "tailwindcss": "^3.0.2",
    "typescript": "~3.9.2",
    "uuid": "^8.3.2"
  },
  "devDependencies": {
    "@parcel/config-default": "^2.10.0",
    "@parcel/resolver-default": "^2.10.0",
    "@parcel/transformer-typescript-tsc": "^2.10.0",
    "http-proxy-middleware": "^2.0.6",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "parcel-resolver-ignore": "^2.1.5",
    "process": "^0.11.10"
  },
  "proxy": "http://localhost:3003",
  "scripts": {
    "start": "parcel serve -p 3000 src/index.html",
    "build": "parcel build src/index.html",
    "test": "echo Skipping for now && exit 0"
  },
  "eslintConfig": {
    "extends": "react-app"
  },
  "parcelIgnore": [
    "environment.css"
  ],
  "browserslist": {
    "production": [
      ">0.15%"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  }
}
