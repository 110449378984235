import React from 'react';
import {LeafSensorData} from "../dbo/LeafSensorData";
import {DateTimeFormatter} from '@js-joda/core';
import {PlantDto} from "dto/PlantDto";
import {ProfileDto} from "dto/ProfileDto";

function renderRow(profile: ProfileDto, data: LeafSensorData) {
    return <tr>
        <td>{data.id}</td>
        <td>{data.timestamp.format(DateTimeFormatter.ISO_LOCAL_DATE_TIME)}</td>
        {profile.sensors.map(s => <td>{(data as any)[s.id]}</td>)}
    </tr>;
}


function renderHeader(profile: ProfileDto) {
    return <tr>
        <th>ID</th>
        <th>Date</th>
        {profile.sensors.map(s => <th key={s.id}>{s.description}</th>)}
    </tr>;
}

interface DeviceDataProps {
    jwt: string;
    profile: ProfileDto;
    data: LeafSensorData[];
    plant: PlantDto | null;
    onlyDownload: boolean;
}


export function DeviceData(props: DeviceDataProps) {
    const {data, jwt, onlyDownload} = props;
    return <section>
        <h2>Device Data <small><a href={`/api/v2/download/csv?plantId=${props.plant?.id}&jwt=${jwt}`}
                                  download>download</a></small></h2>
        {onlyDownload ? null : (
            <table>
                <thead>
                {renderHeader(props.profile)}
                </thead>
                <tbody>
                {data.map(d => renderRow(props.profile, d))}
                </tbody>
            </table>)}
    </section>;
}
