import React from 'react';
import './Card.css';

export interface CardProps {
    header: React.ElementType;
    body: React.ElementType;
}

export function Card(props: React.PropsWithChildren<{}> ) {
    return <div className="card">
        {props.children}
    </div>;

}

