import React, {useState} from 'react';
import './Login.css';

import {Modal} from "./modal";

export interface LoginProps {
    onSubmit: (username: string, password: string) => void;
    error?: string;
}

export function Login(props: LoginProps) {
    const [state, setState] = useState({
        username: '',
        password: '',
        keepLoggedIn: false
    });

    function handleUsernameChange(event: any) {
        setState({...state, username: event.target.value});
    }

    function handlePasswordChange(event: any) {
        setState({...state, password: event.target.value});
    }

    function handleSubmit(event: any) {
        event.preventDefault();
        props.onSubmit(state.username, state.password);
    }

    function renderError() {
        if (props.error) {
            return <div className="form-row error"> Unable to login: {props.error}</div>;
        } else {
            return <div className="form-row">&nbsp;</div>;
        }

    }

    return <div>
        <form onSubmit={handleSubmit}>
            <Modal>
                <div>Please enter username and password</div>
                <div>
                    <div className="form-row">
                        <span className="label">Name:</span>
                    </div>
                    <div className="form-row">
                        <input id="login-username" type="text" value={state.username}
                               onChange={handleUsernameChange}/>
                    </div>
                    <div className="form-row">
                        <span className="label">Password:</span>
                    </div>
                    <div className="form-row">
                        <input id="login-password" type="password" value={state.password}
                               onChange={handlePasswordChange}/>
                    </div>
                    <div className="form-row checkbox">
                        &nbsp;
                    </div>
                    <div className="form-row checkbox">
                        <input id="keep-logged" type="checkbox" onChange={() => {
                            setState({...state, keepLoggedIn: !state.keepLoggedIn})
                        }} checked={state.keepLoggedIn}/>Keep me logged in
                    </div>
                    {renderError()}
                </div>
                <div>
                    <div className="button-row">
                        <button>Login</button>
                    </div>
                </div>
            </Modal>
            <input type="submit" style={{display: 'none'}}/>
        </form>
    </div>;
}
