import {RangeWidth} from "../dbo/RangeWidth";
import React from 'react';

interface RangeSelectorProps {
    ranges: RangeWidth[],
    selectedRangeId: string,
    onRangeSelected: (interval: RangeWidth) => void
}

export function RangeSelector(props: RangeSelectorProps) {
    const rangeById: {
        [key: string]: RangeWidth
    } = {};
    for (let range of props.ranges) {
        rangeById[range.id] = range;
    }
    return <select value={props.selectedRangeId} onChange={event => props.onRangeSelected(rangeById[event.target.value])}>
        {props.ranges.map(range =>
            <option key={range.id}
                    value={range.id}>{range.description}</option>
        )}
    </select>
}
