/**
 * Render the given content only if the role is the one specified
 */
import {LoginService} from "../service/LoginService";
import * as React from 'react';

export interface IfRoleProps {
    role: string;
}
export function IfRole(props: React.PropsWithChildren<IfRoleProps>) {
    const login = LoginService.getLoginInformation();
    if (login?.hasRole(props.role)) {
        return <React.Fragment>
            {props.children}
        </React.Fragment>;
    } else {
        return null;
    }
}
