import {getAuthorization, LoginService} from "./LoginService";
import {HttpException} from "../exception";
import {TaskDto} from "dto/TaskDto";

const ENDPOINT = "/api/v2/analysis";

export type TaskStatus = 'invalid' | 'started' | 'finished'

/**
 * Enqueue a processing task. Return the enqueue task id
 * @param files
 */
export async function uploadFilesForAnalysis(files: File[]): Promise<TaskDto> {
    const formData = new FormData();
    for (let i = 0; i < files.length; ++i) {
        const file = files[i];
        formData.append('csv', file);
    }
    const response = await fetch(`${ENDPOINT}/enqueue`, {
        headers: {...getAuthorization()},
        method: 'post',
        body: formData
    });

    if (!response.ok) {
        throw new HttpException(response);
    }

    const result = await response.json();
    return result as TaskDto;
}

export async function getTaskList(): Promise<TaskDto[]> {
    const loginInformation = LoginService.getLoginInformation();
    const response = await fetch(`${ENDPOINT}/task?user=${loginInformation.username}`, {
        headers: {...getAuthorization()},
    });
    if (!response.ok) {
        throw new HttpException(response);
    }

    const result = await response.json() as TaskDto[];
    for (const r of result) {
        r.creationDate = new Date(r.creationDate);
    }
    console.log("RES", result);
    return result as TaskDto[];

}

export async function getTask(taskId: number): Promise<TaskDto> {
    const response = await fetch(`${ENDPOINT}/task/${taskId}`, {
        headers: {...getAuthorization()},
    });
    if (!response.ok) {
        throw new HttpException(response);
    }

    const result = await response.json();
    return result as TaskDto;


}
