import React from 'react';
import {WeatherData} from "../dbo/WeatherData";
import {Chart} from "./Chart";
import {ZonedDateTime} from '@js-joda/core';
import {ChartDataElement} from "../dbo/ChartDataElement";


export function RainChart(props: {weatherData: WeatherData[], xMin: ZonedDateTime, xMax: ZonedDateTime, label: string}) {
    const { weatherData, xMin, xMax, label } = props;
    // const rainAmount = weatherData.map(wd => wd.rainAmount3h);
    // const rainMin = rainAmount.reduce((a, b) => Math.min(a, b), 0);
    // const rainMax = rainAmount.reduce((a, b) => Math.max(a, b), 0);
    const rainData: ChartDataElement[] = weatherData.map(wd => ({
        value: wd.rainAmount1h || 0,
        timestamp: wd.date.toEpochSecond()
    }));

    return <div key={'weather'} className="charts">
        <Chart title={label} data={rainData}
               xMin={xMin.toEpochSecond()}
               xMax={xMax.toEpochSecond()}
               hasBaseline={true}
               // yMin={rainMin}
               // yMax={rainMax}
        />
    </div>;
}
