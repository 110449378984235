import {SensorDto} from "../../../dto/SensorDto";
import React from "react";
import {v4} from "uuid";

export interface SensorEditProps {
    sensors: SensorDto[];
    onSensorChanged: (previous: SensorDto, current: SensorDto) => void;
    onSensorAdded: (sensor: SensorDto) => void;
    onSensorDeleted: (sensor: SensorDto) => void;
}

export function SensorsEdit(props: SensorEditProps) {
    function onIdChanged(sensor: SensorDto, id: string) {
        console.log("ID changed to", id);
        props.onSensorChanged(sensor, {...sensor, id});
    }

    function onDescriptionChanged(sensor: SensorDto, description: string) {
        console.log("description changed to", description);
        props.onSensorChanged(sensor, {...sensor, description})
    }

    function onFormulaChanged(sensor: SensorDto, formula: string) {
        console.log("formula changed to", formula);
        props.onSensorChanged(sensor, {...sensor, formula});
    }

    function onUomChanged(sensor: SensorDto, uom: string) {
        console.log("uom changed to", uom);
        props.onSensorChanged(sensor, {...sensor, uom});
    }

    function onSensorAdded() {
        props.onSensorAdded({
            formula: undefined,
            description: '',
            id: '',
            chartType: 'line'

        })
    }

    function onSensorDeleted( sensor: SensorDto ) {
       console.log("on sensor", sensor,"deleted");
       props.onSensorDeleted(sensor);
    }

    for (const sensor of props.sensors) {
        const s = sensor as any;
        if (!s.uuid) {
            s.uuid = v4();
        }
    }
    return <div style={{display: "grid", gridTemplateColumns: "auto auto auto auto auto"}}>
        <div key='header' style={{display: "contents"}}>
            <b>Name</b>
            <b>Description</b>
            <b>Formula</b>
            <b>Uom</b>
            <span><button onClick={onSensorAdded}>Add</button></span>

        </div>
        {props.sensors.map(s => (
            <div key={(s as any).uuid} style={{display: "contents"}}>
                <input value={s.id} onChange={e => onIdChanged(s, e.target.value)}/>
                <input value={s.description} onChange={e => onDescriptionChanged(s, e.target.value)}/>
                <input value={s.formula} onChange={e => onFormulaChanged(s, e.target.value)}/>
                <input value={s.uom} onChange={e => onUomChanged(s, e.target.value)}/>
                <span><button onClick={ () => onSensorDeleted(s)}>Delete</button></span>
            </div>
        ))}
    </div>;
}
