import React, {useState} from "react";


export interface SortingInfo<T> {
    column: keyof T,
    order: 'asc' | 'desc'
}


export interface ColumnInfo<T> {
    description: string;
    editable?: boolean;
    renderer?: (value: T) => JSX.Element,
    editRenderer?: (value: T, onEntryChanged: (entry: T) => void) => JSX.Element;
}

export type ColumnOptions<T> = Partial<Record<keyof T, ColumnInfo<T>>>;


export interface TableProps<T> {
    values: T[],
    columns: ColumnOptions<T>,
    onSortChanged: (sort: SortingInfo<T>) => void,
    sortBy?: SortingInfo<T> | undefined,
    editable: boolean | ((row: T) => boolean),
    onEntrySave?: (entry: T) => Promise<void>,
    onEntryAdd?: () => Promise<T>,
    onEntryDelete?: (entry: T) => Promise<void>
    makeId?: (entry: T) => string;
}

interface TableState<T> {
    editing: { [id: string]: T }
}


function defaultMakeId<T>(entry: T): string {
    return String((entry as any).id);
}

const initialState = {editing: {}};

export function Table<T>(props: TableProps<T>) {
    const [state, setState] = useState(initialState as TableState<T>);
    const makeId = props.makeId || defaultMakeId;

    function onEdit(entry: T) {
        const id: string = makeId(entry);
        const editing = {...state.editing};
        editing[id] = {...entry};
        setState({...state, editing});
    }

    function onCancel(entry: T) {
        const id: string = makeId(entry);
        const editing = {...state.editing};
        delete editing[id];
        setState({...state, editing});
    }

    // function onDelete(entry: T) {
    //     if (props.onEntryDelete) {
    //         props.onEntryDelete(entry);
    //     }
    // }

    function renderColumnHeader(columnId: string): JSX.Element {
        const columnKey = columnId as keyof T;
        const columnInfo = (props.columns as any)[columnId];

        function clickHandler(e: any) {
            if (props.sortBy?.column === columnKey) {
                props.onSortChanged({
                    column: columnKey,
                    order: props.sortBy.order === "asc" ? "desc" : "asc"
                });
            } else {
                props.onSortChanged({
                    column: columnKey,
                    order: 'asc'
                });

            }
        }

        let sortIcon = '';
        if (columnId === props.sortBy?.column) {
            sortIcon = props.sortBy?.order === 'asc' ? '▲' : '▼';
        }
        return <b key={columnId} onClick={clickHandler} className="clickable">{columnInfo.description}{sortIcon}</b>;
    }

    function onRowChanged(row: T) {
        console.log("Row changed to", row);
        const id = makeId(row);
        const editing = {...state.editing};
        editing[id] = row;
        setState({...state, editing});
    }

    function isRowEditable(entry: T, editable: undefined | boolean | ((e: T) => boolean)): boolean {
        if (editable === true) {
            return true;
        }
        if (editable === false || editable === undefined) {
            return false;
        }
        return editable(entry);
    }

    function renderRow(entry: T, columns: ColumnOptions<T>) {
        const id = makeId(entry);
        const editing = !!state.editing[id];
        const row = state.editing[id] || entry;

        function renderCell(c: keyof ColumnOptions<T>): JSX.Element {
            const key = c as string;
            const info = columns[c];
            if (editing && isRowEditable(entry, info?.editable)) {
                const renderer = (info as ColumnInfo<T>).editRenderer;
                if (renderer) {
                    return <span key={key}>{(renderer as any)(row, onRowChanged)}</span>;
                } else {
                    return <span key={key}>{(row as any)[c]}</span>;
                }
            } else {
                const renderer = (info as ColumnInfo<T>).renderer;
                if (renderer) {
                    return <span key={key}>{(renderer as any)(row)}</span>;
                } else {
                    return <span key={key}>{(row as any)[c]}</span>;
                }
            }
        }

        return <div key={makeId(entry)} className="backoffice-row">
            {Object.keys(columns).map(c => renderCell(c as keyof ColumnOptions<T>))}
            {isRowEditable(entry, props.editable) ? renderEdit(row, editing) : <span/>}
        </div>
    }

    const nColumns = Object.keys(props.columns).length + (props.editable ? 1 : 0);

    async function onSave(row: T) {
        const editing = {...state.editing};
        delete editing[makeId(row)];
        setState(state => ({...state, editing}));
        if (props.onEntrySave) {
            await props.onEntrySave(row);
        }
    }

    function renderEdit(row: T, editing: boolean) {
        if (editing) {
            return <span>
                <button onClick={() => onSave(row)}>Save</button>
                <button onClick={() => onCancel(row)}>Cancel</button>
            </span>
        } else {
            return <span>
                <button onClick={() => onEdit(row)}>Edit</button>
            </span>

        }
    }

    return <div style={{
        display: 'grid',
        gridTemplateColumns: `repeat( ${nColumns}, auto)`,
        gridColumnGap: "1em",
        gridRowGap: "0.5em"
    }}>
        <div className="backoffice-row">
            {Object.keys(props.columns).map(c => renderColumnHeader(c))}
            {props.editable ? <div></div> : null}
        </div>
        {props.values.map(v => renderRow(v, props.columns))}

    </div>;
}

