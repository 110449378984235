/**
 * Promisified version of setTimeout
 */
import {HttpException} from "../exception";
import {getAuthorization} from "../service/LoginService";
import {useEffect, useState} from "react";


export async function sleep(ms: number): Promise<void> {
    const p = new Promise<void>(function (resolve, reject) {
        setTimeout(() => resolve(), ms);
    });
    return p;
}


export async function requestOld(input: RequestInfo, init?: RequestInit): Promise<Response> {
    if (!init) {
        init = {
            headers: {}
        };
    }
    if (!init.headers) {
        init.headers = {};
    }
    const jwt = localStorage.jwt;
    const headers = init.headers as Record<string, string>;
    headers["Authorization"] = `Bearer ${jwt}`;
    if (init.body) {
        init.body = JSON.stringify(init.body);
        headers['Content-Type'] = 'application/json';
    }
    const result = await fetch(input, init);
    if (!result.ok) {
        throw new HttpException(result);
    }
    return result;
}

export async function request<T>(input: RequestInfo, init?: RequestInit): Promise<T | undefined> {
    if (!init) {
        init = {
            headers: {}
        };
    }
    const headers = {...getAuthorization(), ...init.headers} as Record<string, string>;
    if (init.body) {
        init.body = JSON.stringify(init.body);
        headers['Content-Type'] = 'application/json';
    }
    const result = await fetch(input, init);
    if (!result.ok) {
        throw new HttpException(result);
    }
    if (result.status === 200) {
        return result.json();
    } else {
        return undefined;
    }
}


/**
 * Alphanumeric sort, see https://stackoverflow.com/questions/4340227/sort-mixed-alpha-numeric-array
 */
const reA = /[^a-zA-Z]/g;
const reN = /[^0-9]/g;

export function sortAlphaNum(a: any, b: any) {
    a = String(a);
    b = String(b);
    const aA = a.replace(reA, "");
    const bA = b.replace(reA, "");
    if (aA === bA) {
        const aN = parseInt(a.replace(reN, ""), 10);
        const bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
        return aA > bA ? 1 : -1;
    }
}

export function sortByField<T>(data: T[], field: keyof T, order: 'asc' | 'desc') {
    if (order === 'asc') {
        data.sort((a, b) => sortAlphaNum(a[field], b[field]));
    } else {
        data.sort((a, b) => -sortAlphaNum(a[field], b[field]));
    }

}


export function params(params: any) {
    return new URLSearchParams(params).toString();
}

/**
 * Given a list of items, return a map from their id
 * If the same id appears multiple times, only the last item will be present
 * in the result
 * @param items items
 * @param key (optional) name of the field to use as id, 'id' is the default
 */
export function indexBy<T>(items: T[], key: keyof T): { [key: string]: T } {
    const result: { [key: string]: T } = {};
    for (const item of items) {
        const k: string = String(item[key]);
        result[k] = item;
    }
    return result;
}

export function groupBy<T>(items: T[], key: keyof T): { [key: string]: T[] } {
    const result: { [key: string]: T[] } = {};
    for (const item of items) {
        const k: string = String(item[key]);
        if (result[k] === undefined) {
            result[k] = [];
        }
        result[k].push(item);
    }
    return result;

}

// Issues with import Timeout here...
const INITIAL_STATE: Object | undefined = undefined;

export function useInterval(callback: () => void, interval: number | null) {
    console.log("useInterval", callback, interval);
    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {

        if (state) {
            console.log("Clearing interval", state);
            clearInterval(state as any);
        }

        if (interval) {
            const timer = setInterval(callback, interval);
            setState(timer);
        } else {
            setState(undefined);
        }

        return () => {
            if (state) {
                clearInterval(state as any);
            }
        };
    }, [interval]);
}
