import React, {useEffect, useState} from 'react';
import {AppState} from "../../state";
import {RangeWidth} from "../../dbo/RangeWidth";
import {ADMIN_PAGE_EMPTY_STATE, getInitialAdminPageState} from "./AdminPageState";
import './AdminPage.css';
import {LeafSensorInfo} from "../LeafSensorInfo";
import {LeafSensorService} from "../../service/LeafSensorService";
import {PlantService} from "../../service/PlantService";
import {Spinner} from "../Spinner";
import {LeafSensorDto} from "../../dto/LeafSensorDto";
import {PlantDto} from "../../dto/PlantDto";

interface AdminPageProps {
    state: AppState,
    customerId: string;
    onRangeSelected: (rangeWidth: RangeWidth) => void,
    // used to reload page when needed
}

export function AdminPage(props: AdminPageProps) {

    const [state, setState] = useState(ADMIN_PAGE_EMPTY_STATE);

    useEffect(() => {
        getInitialAdminPageState(props.customerId).then(newState => {
            setState(newState);
        });
    }, [props.customerId]);

    async function toggleNotificationStatus(leafSensorId: string, notificationEnabled: boolean) {
        await LeafSensorService.setNotificationFlag(leafSensorId, notificationEnabled);
        for (const leafSensor of state.leafSensors) {
            if (leafSensor.id === leafSensorId) {
                leafSensor.notificationEnabled = notificationEnabled;
                break;
            }
        }
        setState({...state});
    }

    async function savePlant(leafSensor: LeafSensorDto, plant: PlantDto) {
        const savedPlant = await PlantService.savePlant(plant);
        const newState = {...state, leafSensors: []};
        for (let leafSensor of state.leafSensors) {
            if (savedPlant.devId && leafSensor.id === savedPlant.devId.toString()) {
                leafSensor = {...leafSensor};
                if (savedPlant.id) {
                    leafSensor.plantId = savedPlant.id.toString()
                }
                leafSensor.description = savedPlant.description;
                leafSensor.name = savedPlant.name;
                newState.plantByLeafSensorId[leafSensor.id] = savedPlant;
            }
            newState.leafSensors.push(leafSensor);
        }
        setState(newState);
    }

    if (state.loadingStatus === "pending") {
        return <Spinner/>;
    }
    return <div>
        <h2 >Leaf Sensors</h2>
        <div className='admin-page-device-list'>
            {state.leafSensors.map(ls => <LeafSensorInfo key={ls.id} leafSensor={ls}
                                                         plant={state.plantByLeafSensorId[ls.id]}
                                                         onPlantSave={savePlant}
                                                         onNotificationFlagToggled={toggleNotificationStatus}/>)}
        </div>
    </div>
}
