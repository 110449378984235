import {SensorDto} from "dto/SensorDto";
import React from "react";

export interface SensorsViewProps {
    sensors: SensorDto[]
}


export function SensorsView(props: SensorsViewProps) {
    return <div style={{display:"grid", gridTemplateColumns: "auto auto"}}>
        {props.sensors.map(s => (
            <div key={s.id} style={{display: "contents"}}>
                <span>{s.id}</span>
                <span>{s.description}</span>
            </div>
        ))}
    </div>;
}
