import React from 'react';
import {CustomerDto} from "dto/CustomerDto";
import {UserDto} from "dto/UserDto";
import './BackofficePage.css';
import {Navigation, NavigationItem} from "../Navigation";
import {LoginInformation} from "../../state";
import {LoginService} from "../../service/LoginService";
import {Route, Routes} from 'react-router-dom';
import {CustomerPage} from "./CustomerPage";
import {DevicePage} from "./DevicePage";
import {ProfilePage} from "./profile/ProfilePage";


export interface BackofficePageState {
    customers: CustomerDto[];
    usersByCustomerId: { [customerId: string]: UserDto[] };
    editedCustomer: CustomerDto | undefined;
    initDone: boolean;
}

export interface Props {
    customerId: string;
}

export function BackofficePage(props: Props) {
    const loginInformation = LoginService.getLoginInformation() as LoginInformation;

    const backofficeItems: NavigationItem[] = [{
        type: 'entry',
        destination: "/backoffice/customer",
        description: "Customers",
        requiredRole: undefined
    },
        {
            type: 'entry',
            destination: "/backoffice/device",
            description: "Devices",
            requiredRole: undefined
        }, {
            type: 'entry',
            destination: "/backoffice/profile",
            description: "Profiles",
            requiredRole: undefined
        }, {
        id: 'spacer',
        type: 'spacer'
        }

    ];


    return <div style={{display: "flex", flexDirection: "column"}}>
        <Navigation loginInformation={loginInformation} items={backofficeItems}/>
        <div className="backoffice">
            <Routes>
                <Route path={`/customer`} element={
                    <CustomerPage customerId={props.customerId}></CustomerPage>
                }>
                </Route>
                <Route path={`/device`} element={
                    <DevicePage customerId={props.customerId}/>
                }>
                </Route>
                <Route path={`/profile`} element={
                    <ProfilePage customerId={props.customerId}/>
                }>
                </Route>
            </Routes>
        </div>
        ;

    </div>
}
