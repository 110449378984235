import {HttpException} from "../exception";
import {getAuthorization, getJwt} from "./LoginService";
import {CustomerDto} from "../dto/CustomerDto";

const ENDPOINT = "/api/v2/customer"


export async function getCustomers(): Promise<CustomerDto[]> {
    const jwt = getJwt();
    const response = await fetch(`${ENDPOINT}/`, {
        headers: {
            "Authorization": `Bearer ${jwt}`
        },
        method: "get"
    });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json();
    return result as CustomerDto[];
}

export async function getCustomerById(customerId: string): Promise<CustomerDto> {
    const response = await fetch(`${ENDPOINT}/${customerId}`, {
        headers: {
            ...getAuthorization()
        },
        method: "get"
    });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json();
    return result as CustomerDto;

}

export async function getCustomersByAncestorId(customerId: string | undefined): Promise<CustomerDto[]> {
    if (!customerId) {
        return [];
    }
    const response = await fetch(`${ENDPOINT}?ancestor=${customerId}`, {
        headers: {
            ...getAuthorization()
        },
        method: "get"
    });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json() as CustomerDto[];
    result.sort((a, b) => a.description.localeCompare(b.description));
    return result as CustomerDto[];
}


export async function saveCustomer(customer: CustomerDto): Promise<CustomerDto> {
    const response = await fetch(`${ENDPOINT}/`,
        {
            method: 'post',
            headers: {
                ...getAuthorization(),
                'content-type': 'application/json'
            },

            body: JSON.stringify(customer)
        });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json();
    return result as CustomerDto;
}

