import React, {ChangeEvent, useState, useEffect} from 'react';
import {Card} from "./Card";

import {v4} from "uuid";
import {LoginService} from "../service/LoginService";
import {LoginInformation} from "../state";
import {getCustomers} from "../service/CustomerService";
import {AdminPlantDto} from "../dto/AdminPlantDto";
import {LeafSensorDto} from "../dto/LeafSensorDto";
import {PlantDto} from "../dto/PlantDto";
import {CalibrationData} from "../dto/CalibrationData";
import {CustomerDto} from "../dto/CustomerDto";

function makePlant(): AdminPlantDto {
    return {
        id: undefined,
        calibrationData: {},
        description: '',
        name: '',
        regDate: new Date(),
        weatherLocationId: null,
        devId: null,
        uuid: v4(),
        gps: {x: 0, y: 0},
        deviceProfileId: '',
        baselineCalculationDuration: 72 * 60
    };
}


export interface LeafSensorInfoProps {
    leafSensor: LeafSensorDto;
    plant?: PlantDto;
    onNotificationFlagToggled: (leafSensorId: string, notificationEnabled: boolean) => void;
    onPlantSave: (leafSensor: LeafSensorDto, plant: PlantDto) => Promise<void>;
}


function header(leafSensor: LeafSensorDto, plant: PlantDto | undefined, showHistory: boolean, history: PlantDto[], onShowHistory: () => void,
                onPlantSelected: (plant: PlantDto) => void) {
    const label = plant ? plant.name : leafSensor.name;

    function getPlant(id: string) {
        for (const p of history) {
            if (p.id === id) {
                return p;
            }
        }
        throw new Error("Internal error");
    }

    if (showHistory) {
        return <select onChange={event => onPlantSelected(getPlant(event.target.value))}>
            {history.map(p => <option selected={plant && p.id === plant.id} key={String(p.id)}
                                      value={String(p.id)}>{p.description}</option>)}
        </select>;
    } else {
        return <div>{label} <span onClick={onShowHistory} title="Show History" className="clickable fa fa-history"/>
        </div>;
    }
}


function body(leafSensor: LeafSensorDto, plant: PlantDto | undefined,
              onNotificationEnabledChange: (event: ChangeEvent<HTMLInputElement>) => void) {
    const checkboxId = 'leaf-sensor-notification-enabled-' + leafSensor.id;
    return <div className='grid'>
        <div>Description:</div>
        <div>{plant ? plant.description : null}</div>
        <div>Device id:</div>
        <div> {leafSensor.id}</div>
        <div>Mac Address:</div>
        <div>{leafSensor.macAddress}</div>

        {plant ? <div>Location: </div> : null}
        {plant ? <div>{plant.gps.y}, {plant.gps.x}</div> : null}

        {plant && plant.regDate ? <div>Registration date:</div> : null}
        {plant && plant.regDate ? <div>{plant.regDate.toDateString()}</div> : null}

        <div>Baseline calculation period (minutes):</div>
        <div>{plant?.baselineCalculationDuration}</div>
        <div className='form-row columns-2' style={{display: 'contents'}}>
            <label htmlFor={checkboxId}>Notifications:</label>
            <input name={checkboxId}
                   type='checkbox'
                   onChange={onNotificationEnabledChange}
                   checked={leafSensor.notificationEnabled}/>
        </div>
    </div>;
}

function plantEdit(editedPlant: AdminPlantDto,
                   onNewPlantClicked: (event: any) => void,
                   onPlantSave: (event: any) => Promise<void>,
                   onPlantChanged: (plant: AdminPlantDto) => void,
                   onCancelClicked: () => void) {
    function onCalibrationChanged(field: string, value: string) {
        const ed = editedPlant as AdminPlantDto;
        const calibrationData = {...editedPlant?.calibrationData, [field]: Number(value)} as CalibrationData;
        const newPlant: AdminPlantDto = {
            ...ed,
            calibrationData
        }
        onPlantChanged(newPlant);
    }

    return (<div className="grid">
        <label>Name:</label>
        <input
            onChange={event => onPlantChanged({...editedPlant, name: event.target.value})}
            value={editedPlant.name}/>

        <label>Description:</label>
        <input
            onChange={event => onPlantChanged({...editedPlant, description: event.target.value})}
            value={editedPlant.description}/>

        <label>Lat:</label>
        <input
            type="number"
            min="-90"
            max="90"
            onChange={event => onPlantChanged({
                ...editedPlant,
                gps: {
                    x: editedPlant.gps.x,
                    y: Number(event.target.value)
                }
            })}
            value={editedPlant.gps.y}
        />

        <label>Lon:</label>
        <input
            type="number"
            min="-180"
            max="180"
            onChange={event => onPlantChanged({
                ...editedPlant,
                gps: {
                    y: editedPlant.gps.y,
                    x: Number(event.target.value)
                }
            })}
            value={editedPlant.gps.x}
        />

        <label>BCP (min):</label>
        <input
            type="number"
            min="0"
            onChange={event => onPlantChanged({
                ...editedPlant,
                baselineCalculationDuration: Number(event.target.value)
            })}
            value={editedPlant.baselineCalculationDuration}
        />


        <strong>Calibration values</strong><span></span>

        <span>PDT1</span><input onChange={event => onCalibrationChanged('pdt1', event.target.value)}
                                value={editedPlant.calibrationData.pdt1}/>
        <span>PDT2</span><input onChange={event => onCalibrationChanged('pdt2', event.target.value)}
                                value={editedPlant.calibrationData.pdt2}/>
        <span>PDT3</span><input onChange={event => onCalibrationChanged('pdt3', event.target.value)}
                                value={editedPlant.calibrationData.pdt3}/>

        <span>PDR1</span><input onChange={event => onCalibrationChanged('pdr1', event.target.value)}
                                value={editedPlant.calibrationData.pdr1}/>
        <span>PDR2</span><input onChange={event => onCalibrationChanged('pdr2', event.target.value)}
                                value={editedPlant.calibrationData.pdr2}/>
        <span>PDR3</span><input onChange={event => onCalibrationChanged('pdr3', event.target.value)}
                                value={editedPlant.calibrationData.pdr3}/>

        <div className='button-row' style={{gridColumn: 'span 2'}}>
            <button onClick={onCancelClicked}>Cancel</button>
            <button onClick={onPlantSave}>Save</button>

        </div>

    </div>);
}

function plantMove(plant: AdminPlantDto, customerId: string, customers: CustomerDto[], onCustomerSelected: (customerId: string) => void,
                   onCancelClicked: () => void,
                   onConfirmClicked: () => void
) {
    return <div>
        <div>Choose a new customer</div>
        <select value={customerId} onChange={event => onCustomerSelected(event.target.value)}>
            {customers.map(customer =>
                <option key={customer.id}
                        value={customer.id}>{customer.description}</option>
            )}
        </select>
        <div className='button-row'>
            <button onClick={onCancelClicked}>Cancel</button>
            <button onClick={onConfirmClicked}>Confirm</button>
        </div>
    </div>;
}


export interface LeafSensorInfoState {
    state: 'initial' | 'editing' | 'moving';

    // Information about the new plant being edited or added while in 'editing' state
    editedPlant: AdminPlantDto | undefined;
    // List of customers when in 'editing' state
    customers: CustomerDto[] | undefined;
    // Customer that we want to move to when in 'moving' state
    movingToCustomerId: string | undefined;

    // True if the plant is being created, false if it being edited
    isNewPlant: boolean
    showHistory: boolean;
    plantHistory: PlantDto[];
    selectedHistory: PlantDto | undefined;
}

const INITIAL_STATE: LeafSensorInfoState = {
    state: 'initial',
    customers: undefined,
    editedPlant: undefined,
    movingToCustomerId: undefined,
    isNewPlant: false,
    showHistory: false,
    plantHistory: [],
    selectedHistory: undefined

};

export function LeafSensorInfo(props: LeafSensorInfoProps) {
    const {leafSensor, plant} = props;
    const [state, setState] = useState(INITIAL_STATE);

    function onNotificationChange(event: ChangeEvent<HTMLInputElement>) {
        props.onNotificationFlagToggled(leafSensor.id, !leafSensor.notificationEnabled);
    }

    useEffect(() => {
        if (state.state === 'moving') {
            getCustomers().then((customers: CustomerDto[]) => {
                setState(state => {
                    const newState = {...state, customers};
                    return newState;
                });
            });
        }
    }, [state.state])


    function onNewPlantClicked(event: any) {
        const editedPlant = makePlant();
        editedPlant.devId = leafSensor.id;
        if (!editedPlant.calibrationData) {
            editedPlant.calibrationData = {};
        }
        editedPlant.deviceProfileId = plant?.deviceProfileId || '';
        setState({...state, state: 'editing', editedPlant, isNewPlant: true})
    }

    function onEditClicked(event: any) {
        const editedPlant = {...plant} as AdminPlantDto;
        if (!editedPlant.devId) {
            editedPlant.devId = leafSensor.id;
        }
        if (!editedPlant.calibrationData) {
            editedPlant.calibrationData = {};
        }
        editedPlant.gps = {...editedPlant.gps};
        setState({...state, state: 'editing', editedPlant, isNewPlant: false})
    }

    async function onPlantSave(event: any) {
        if (state.editedPlant) {
            await props.onPlantSave(leafSensor, state.editedPlant);
            setState(INITIAL_STATE);
        }
    }

    function onPlantChanged(editedPlant: AdminPlantDto) {
        if (!editedPlant.calibrationData) {
            editedPlant.calibrationData = {};

        }
        setState({...state, editedPlant});
    }

    function onShowHistory() {
        setState({...state, showHistory: true});
    }

    function onPlantSelected(plant: PlantDto) {
        setState({...state, selectedHistory: plant});
    }

    function onCustomerSelected(customerId: string) {
        setState({...state, movingToCustomerId: customerId});
    }

    function onChangeCustomerClicked() {
        const loginInformation = LoginService.getLoginInformation() as LoginInformation;
        const editedPlant = {...plant} as AdminPlantDto;
        // FIXME
        setState({...state, state: 'moving', editedPlant, movingToCustomerId: loginInformation.customerId});
    }

    const canMovePlant = false;

    function onCancelClicked() {
        setState(INITIAL_STATE);
    }

    function onMoveConfirmClicked() {

    }

    function renderPlantState() {
        switch (state.state) {
            case 'initial':
                return (<div className='button-row'>
                    <button onClick={onEditClicked}>Edit</button>
                    <button onClick={onNewPlantClicked}>Move to a new plant</button>
                    {canMovePlant ?
                        <button onClick={onChangeCustomerClicked}>Move to a different customer</button> : null}
                </div>);

            case 'editing':
                return plantEdit(state.editedPlant as AdminPlantDto, onNewPlantClicked, onPlantSave, onPlantChanged, onCancelClicked);

            case 'moving':
                return plantMove(state.editedPlant as AdminPlantDto, state.movingToCustomerId as string, state.customers || [], onCustomerSelected,
                    onCancelClicked, onMoveConfirmClicked);
            default:
                return <div>Unknown state</div>;
        }
    }

    return <Card>
        {header(leafSensor, state.selectedHistory || plant, state.showHistory, state.plantHistory, onShowHistory, onPlantSelected)}
        {body(leafSensor, plant, onNotificationChange)}
        {renderPlantState()}
    </Card>;
}
