import {useState} from "react";

export interface UseAsyncOptions<T> {
    // Promise to call
    promiseFn: () => Promise<T>;
    // If this is true, promiseFn will not be called until the first time
    // that 'watch' changes
    // If the value is changed from true to false in subsequent calls
    // promiseFn will be called if it has not be called at least once
    waitChange?: boolean
    // When this value changes, recall promiseFn and get a new promise
    // note that a shallow comparison is made, so it's probably
    // not so useful to pass an object
    watch?: any,
}

interface UseAsyncState<T> {
    promiseFn: () => Promise<T>;
    promise?: Promise<T>;
    previousWatch?: any;
    status: 'initial' | 'pending' | 'fulfilled' | 'rejected';
    data?: T;
    error?: any;
    reload?: boolean;
}

interface UseAsyncResultBase {
    reload: () => void;
}


export interface UseAsyncInitialResult extends UseAsyncResultBase {
    status: 'initial';
}

export interface UseAsyncPendingResult extends UseAsyncResultBase{
    status: 'pending';
}

export interface UseAsyncFulfilledResult<T> extends UseAsyncResultBase{
    status: 'fulfilled';
    data: T;
    override: (t: T) => void;
}

export interface UseAsyncRejectedResult extends UseAsyncResultBase{
    status: 'rejected';
    error: any;
}

type UseAsyncResult<T> = UseAsyncInitialResult | UseAsyncPendingResult | UseAsyncFulfilledResult<T> | UseAsyncRejectedResult;


export function useAsync<T>(options: UseAsyncOptions<T>): UseAsyncResult<T> {

    function reload() {
        setState(state => ({...state, reload: true}));
    }

    // function update(newState: T) {
    //     setState( state => ({...state, data: newState}));
    // }

    function override(t: T) {
        setState( state => ({...state, data: t}));
    }

    function makeResult(state: UseAsyncState<T>): UseAsyncResult<T> {
        switch (state.status) {
            case 'fulfilled':
                const fulfilledResult: UseAsyncFulfilledResult<T> = {
                    status: "fulfilled",
                    data: state.data as T,
                    reload,
                    override
                };
                return fulfilledResult;
            case 'rejected':
                const rejectedResult: UseAsyncRejectedResult = {
                    status: 'rejected',
                    error: state.error,
                    reload
                };
                return rejectedResult;
            default:
                return {
                    status: state.status,
                    reload
                };
        }
    }


//    console.log("UseAsync", options.watch);
    const initialState: UseAsyncState<T> = {
        promiseFn: options.promiseFn,
        status: 'initial'

    };
    const [state, setState] = useState(initialState);
    if ((state.status === 'initial' && !options.waitChange) ||
        options.watch !== state.previousWatch ||
        state.reload) {
//        console.log("useAsync Changed !");
        const promise = options.promiseFn();
        const newState: UseAsyncState<T> = {
            ...state,
            promise,
            status: 'pending',
            previousWatch: options.watch,
            reload: false
        };
        promise.then(result => {
            setState( function(oldState) {
//                console.log(oldState.promise, promise);
                // If the promise in state has changed, it means that 'watch' property triggered another call fo 'promiseFn'
                // so we should ignore this result
                if (oldState.promise === promise) {
//                    console.log("useAsync updating state with result");
                    return {...oldState, status: 'fulfilled', data: result, error: undefined};
                } else {
//                    console.log("useAsync promise changed, not updating state");
                    return oldState;
                }
            });
        }).catch( error => {
            // Same as above
            setState( function(oldState) {
                if (oldState.promise === promise) {
//                    console.log('useAsync updating state with error');
                    return {...oldState, status: 'rejected', data: undefined, error: error};
                } else {
//                    console.log('useAsync promise changed, not updating state with error');
                    return oldState;
                }
            });
        });
        
        setState(newState);
        return makeResult(newState);
    } else if (options.watch !== state.previousWatch) {
        setState(oldState => ({...oldState, previousWatch: options.watch}));
    }
    return makeResult(state);
}
