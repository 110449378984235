import React, { useEffect } from 'react';
import {LoginService} from "../../service/LoginService";
import { useNavigate } from 'react-router-dom';

export interface LogoutProps {
    onLogout: () => Promise<void>;
}

export function Logout(props: LogoutProps) {
    const navigate = useNavigate();

    function logout() {
        navigate("/");
        LoginService.logout().then( props.onLogout );
    }

    useEffect( logout );
    return <div/>;
}
