import React, {useState} from 'react';

import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {DateTimeFormatter, Instant, ZonedDateTime, ZoneId} from '@js-joda/core';
import '@js-joda/timezone';
import {Toggle} from "./Toggle";
import {ChartDataElement} from "../dbo/ChartDataElement";
import './Charts.css';

export type ChartProps = {
    data: ChartDataElement[],
    title: string,
    xMin?: number,
    xMax?: number,
    yMin?: number,
    yMax?: number,
    syncId?: string,
    reversed?: boolean,
    hasBaseline: boolean
}

function labelFormatter(date: number) {
    const zdt = ZonedDateTime.ofInstant(Instant.ofEpochSecond(date), ZoneId.of("Europe/Rome"));
    return zdt.format(DateTimeFormatter.ofPattern("dd/MM HH:mm"));
}

function tickFormatter(d: number) {
    const zdt = ZonedDateTime.ofInstant(Instant.ofEpochSecond(d), ZoneId.of("Europe/Rome"));
    return zdt.format(DateTimeFormatter.ofPattern("dd/MM HH:mm"));
}


export function Chart(props: ChartProps) {

    let {data, title, syncId} = props;

    const xMin = props.xMin|| 'auto';
    const xMax = props.xMax  || 'auto';
    let yMin = 0;
    let yMax = 0;
    if (data.length > 0) {
        yMin = data.reduce((a, b) => a.value < b.value ? a : b).value;
        yMax = data.reduce((a, b) => a.value > b.value ? a : b).value;
        yMin -= (yMax - yMin) * 0.05;
        if (props.yMin) {
            yMin = Math.max(yMin, props.yMin);
        }
        yMin = Number(yMin.toFixed(3));
    }
    yMax += (yMax - yMin) * 0.05;
    if (props.yMax) {
        yMax = Math.min(yMax, props.yMax);
    }
    yMax = Number(yMax.toFixed(3));
    const [ visible, setState ] = useState(true);
    if (props.data.length === 0) {
        return  <div>
            <h2>{title}<Toggle toggled={visible} onToggle={() => setState(!visible)}></Toggle></h2>
            {visible ? <h4>No data for the selected interval</h4> : null }
        </div>;

    }
    let baselineWarning = props.hasBaseline ? null :  (
        <text x='6em'
              y='1em'
              className='baseline-warning'
              >Baseline not yet computed, data may change in the future</text> );

    // baselineWarning = null;
    return <div>
        <h2>{title}<Toggle toggled={visible} onToggle={() => setState(!visible)}></Toggle></h2>
        { visible ? <ResponsiveContainer width='100%' height={250}>
            <AreaChart
                syncId={syncId}
                data={data}
                margin={{top: 10, right: 30, left: 0, bottom: 0}}
            >
                {baselineWarning}
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis domain={[xMin, xMax]} dataKey="timestamp" type="number"
                       tickFormatter={tickFormatter}/>
                <YAxis width={80} domain={[yMin, yMax]} reversed={props.reversed} />
                <Tooltip labelFormatter={labelFormatter} isAnimationActive={false}/>
                <Area type="monotone" dataKey={'value'} stroke="#000" fill="url(#splitColor)"
                      isAnimationActive={false}/>
            </AreaChart>
        </ResponsiveContainer>
            : <div/> }
    </div>;
}
