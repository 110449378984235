import React from 'react';

import './Toggle.css';

type ToggleProps = {
    toggled: boolean;
    onToggle: () => void;
}

export function Toggle(props: ToggleProps) {
    if (props.toggled) {
        return <small className="toggle clickable" onClick={props.onToggle}>[-]</small>;
    } else {
        return <small className="toggle clickable" onClick={props.onToggle}>[+]</small>;
    }
}