export class HttpException {
    code: number;
    error: string;

    constructor(response: any) {
        this.code = response.status;
        this.error = response.statusText;

    }

    toString() {
        return this.error;
    }
}
