import React, { useState } from 'react';
import {CustomerDto} from "dto/CustomerDto";
import {UserDto} from "dto/UserDto";
import {Card} from "../Card";
import {User} from "./User";

export interface CustomerProps {
    startEditing: boolean;
    customer: CustomerDto;
    users: UserDto[];
    onSaveClicked: (customer: CustomerDto) => void;
    onUserSaveClicked: (user: UserDto) => void;
}

interface State {
    editedCustomer? : CustomerDto;
    editedUser? : UserDto;
}

export function Customer(props: CustomerProps) {
    const {customer, startEditing} = props;
    const initialState: State = {editedCustomer: startEditing ? {...customer} : undefined };
    const [state, setState] = useState(initialState);
    const {editedCustomer, editedUser} = state;

    async function onUserSaveClicked(user: UserDto) {
        await props.onUserSaveClicked(user);
        setState({editedUser: undefined, editedCustomer});
    }

    function updateDescription(description: string) {
        if (!editedCustomer) {
            return;
        }
        const newCustomer : CustomerDto = {...editedCustomer, description};
        const newState = {editedCustomer: newCustomer, editedUser}
        setState(newState );
    }

    function toggleActive(active: boolean) {
        if (!editedCustomer) {
            return;
        }
        const newCustomer = {...editedCustomer, active};
        setState({editedCustomer:newCustomer, editedUser});
    }

    function onAddUserClicked() {
        if (!editedUser) {
            const roles: string[] = [];
            const newUser : UserDto = { customerId : customer.id, roles} as UserDto;
            setState({editedUser: newUser, editedCustomer});
        }
    }

    async function onSaveClicked( ) {
        if (!state.editedCustomer) {
            return;
        } else {
            await props.onSaveClicked(state.editedCustomer);
            setState({...state, editedCustomer: undefined });
        }
    }

    function renderUsers() {
        return (<div className='backoffice-user-list'>
            <div className='backoffice-row'>
                <b>ID</b>
                <b>Login</b>
                <b>Name</b>
                <b>Surname</b>
                <b>Active</b>
                <b>Roles</b>
                <b>Password</b>
                {/*<b>Expire</b>*/}
                <span/>
            </div>
            {editedUser ? <User className='backoffice-row' startEditing={true} editingEnabled={true} user={editedUser} onSave={onUserSaveClicked} />: null }
            {props.users.map( user => <User
                key={user.id}
                className='backoffice-row'
                                      editingEnabled={customer.active}
                                      user={user} onSave={onUserSaveClicked} /> )}
        </div>);
    }


    function onEditClicked() {
        setState({...state, editedCustomer: {...customer}});
    }

    function onCancelClicked() {
        setState({...state, editedCustomer: undefined});
    }


    function renderHeader() {
        let className = 'row';
        if (!customer.active) {
            className += ' disabled';
        }
        if (editedCustomer) {
            return <div className={className}>
                <input placeholder='Description'
                       value={editedCustomer.description}
                       onChange={event => updateDescription(event.target.value)}/>
                <input type='checkbox' checked={editedCustomer.active} onChange={event => toggleActive(event.target.checked)} ></input>  Active
                <span className="spacer"/>
                <button onClick={() => onCancelClicked()}>Cancel</button>
                <button onClick={() => onSaveClicked()}>Save</button>
            </div>

        } else {
            return <div className={className}>
                <span>{customer.description}</span>
                <span className="spacer"/>
                <button onClick={onAddUserClicked}>New User</button>
                <button onClick={() => onEditClicked()}>Edit</button>
            </div>
        }
    }

    return <Card>
        {renderHeader()}
        {renderUsers()}
    </Card>
}

