import React, {ChangeEvent, useEffect, useState} from "react";
import {getTaskList, uploadFilesForAnalysis} from "../../service/AnalysisService";
import {Spinner} from "../Spinner";
import {TaskStatusDto} from "dto/TaskStatusDto";
import {TaskOutputFileDto} from "dto/TaskOutputFileDto";
import {useAsync} from "../../hooks/useAsync";
import {TaskDto} from "dto/TaskDto";
import {LoginService} from "../../service/LoginService";
import {Document, Page, pdfjs} from "react-pdf";
import "react-pdf/dist/cjs/Page/TextLayer.css"
import "react-pdf/dist/cjs/Page/AnnotationLayer.css"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'npm:pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

export interface Props {

}


interface AnalysisPageState {
    status: TaskStatusDto | 'showing';
    files: File[];
    tasks: TaskDto[];
    task: TaskDto | undefined
    errorMessage?: string;

    outputFiles: TaskOutputFileDto[];
}

const INITIAL_STATE: AnalysisPageState = {
    status: 'initial',
    files: [],
    tasks: [],
    task: undefined,
    outputFiles: [],
}

function renderTaskOutputFileLink(file: TaskOutputFileDto) {
    const loginInformation = LoginService.getLoginInformation();
    return <a href={`/api/v2/storage/${file.id}?jwt=${loginInformation.jwt}`} target="_blank">{file.name}</a>
}

// function renderOutputFiles2(files: TaskOutputFileDto[] | undefined) {
//     if (!files) {
//         return null;
//     }
//     return <ul>
//         {files.map(f => <li key={f.id}>{renderTaskOutputFileLink(f)}</li>)}
//     </ul>
// }

function renderTaskOutputFilePdf(file: TaskOutputFileDto) {
    const loginInformation = LoginService.getLoginInformation();
    return <span key={file.id} className="card">
        <div>{file.name}</div>
        <Document file={`/api/v2/storage/${file.id}?jwt=${loginInformation.jwt}`}>
            <Page pageNumber={1}/>
        </Document>
    </span>
}

function renderOutputFiles(files: TaskOutputFileDto[] | undefined) {
    if (!files) {
        return null;
    }
    return <div>
        {files.map(f => renderTaskOutputFilePdf(f))}
    </div>
}


function renderTaskList(tasks: TaskDto[], onTaskClicked: (task: TaskDto) => void) {
    return <div>
        <div className="card" style={{"width": " 100%"}}>
            <div>Executions</div>
            <table>
                <thead>
                <tr>
                    <th>id</th>
                    <th>Date</th>
                    <th>Result</th>
                </tr>
                </thead>
                <tbody>
                {tasks.map(t => <tr key={t.id} className="clickable" onClick={() => onTaskClicked(t)}>
                    <td>{t.id}</td>
                    <td>{t.creationDate.toLocaleString()}</td>
                    <td>{t.status}</td>
                </tr>)}

                </tbody>
            </table>
        </div>
    </div>
}

function renderTaskResult(task: TaskDto | undefined, backToList: () => void) {
    if (!task) {
        return null;
    }
    const loginInformation = LoginService.getLoginInformation();
    return <div>
        <div className="card" style={{"width": " 100%"}}>
            <div>Processing results</div>
            <div>Task Id: {task.id}</div>
            <div>Status: {task.status}</div>
            <div>
                <div>
                    <button><a href={`/api/v2/storage/task/${task.id}?jwt=${loginInformation.jwt}`} target="_blank">Download
                        Zip</a></button>
                </div>
                <div>
                    <button href="#" onClick={backToList}>Back to the list</button>
                </div>
            </div>
        </div>
        <div>
            {renderOutputFiles(task.outputFiles)}
        </div>
    </div>
}

export function AnalysisPage(props: Props) {
    const [state, setState] = useState(INITIAL_STATE);
//    const interval = state.status === 'started' ? 5000 : null;
    const interval = 2000;
    const tasksAsync = useAsync({
        promiseFn: getTaskList
    });

    if (tasksAsync.status === 'fulfilled') {
        console.log("Got data, check if it changed");
        const tasks = tasksAsync.data;
        tasks.sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime());
        if (state.tasks.length !== tasks.length) {
            console.log("Number of tasks changed from", tasks.length, "to", state.tasks.length);
            setState({...state, tasks});
            return;

        }
        for (let i=0; i<tasks.length; ++i) {
            if (tasks[i].id !== state.tasks[i].id  || tasks[i].status !== state.tasks[i].status) {
                console.log(tasks[i].id, tasks[i].status);
                setState({...state, tasks});
                break;
            }

        }
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            console.log("TIMER");
            if (tasksAsync.status === "fulfilled") {
                console.log("Task is", tasksAsync.status);
                if (tasksAsync.data.filter(t => t.status == "started").length > 0) {
                    console.log("Checking for new data");
                    tasksAsync.reload();
                }
            }
            console.log("Forcing update");
            setState(s => {
                return {...s};
            });

        }, 1000);
        return () => clearTimeout(timer);
    }, );


    // useInterval(async () => {
    //     console.log("INTERVAL");
    //     setState(s => { return {...s} ;});
    //     tasksAsync.reload();
    //     // if (tasksAsync.status == "fulfilled") {
    //     //     if (tasksAsync.data.filter(t => t.status == "started").length > 0) {
    //     //         tasksAsync.reload();
    //     //     }
    //     // }
    //     // if (state.task) {
    //     //     console.log("Checking task status");
    //     //     const task = await getTask(state.task.id);
    //     //     console.log("Task:", task);
    //     //     if (task.status !== state.status) {
    //     //         setState(state => {
    //     //             return {...state, status: task.status};
    //     //         })
    //     //     }
    //     // }
    // }, interval);

    function updateFileList(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files || [];
        setState(state => ({...state, files: [...files]}));
        console.log(state);
    }

    function backToList() {
        setState(state => ({...state, status: "initial"}))
    }

    async function uploadFiles() {
        try {
            await uploadFilesForAnalysis(state.files);
            tasksAsync.reload();
            setState(state => ({...state, status: 'initial'}));
        } catch (e) {
            setState(state => ({...state, status: "failed", errorMessage: e.toString()}));
        }
    }

    function showTask(task: TaskDto) {
        setState(state => ({...state, task: task, status: 'showing'}))
    }


    switch (state.status) {
        case 'initial':
            return <main>
                <h1>Data Analysis</h1>
                <div>
                    Upload the files the files to analyze.
                    <p/>
                    You can either upload CSV files or a Zip file containing the CSVs
                    <p/>
                    <input type="file" name="file" accept=".csv, .zip" multiple onChange={updateFileList}/>
                    <button onClick={uploadFiles}>Process</button>
                    {renderTaskList(state.tasks, showTask)}
                </div>
            </main>;
        case 'started':
            return <main>
                <h1>Data Analysis</h1>
                <div>Waiting for processing...</div>
                <Spinner/>
            </main>
        case "failed":
            return <main>
                <h1>Data Analysis</h1>
                <div>Error occurred: {state.errorMessage}</div>
            </main>;
        case "completed":
            return <main>
                <h1>Data Analysis</h1>
                {renderTaskResult(state.task, backToList)}
            </main>;
        case "showing":
            return <main>
                <h1>Data Analysis</h1>
                {renderTaskResult(state.task, backToList)}
            </main>

        default:
            return <main>
                <h1>Data Analysis</h1>
                <div>Internal Error - Please contact administration</div>
            </main>;
    }
}
