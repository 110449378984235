import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import './Navigation.css';
import {LoginInformation} from "../state";

export interface NavigationEntry {
    type: 'entry'
    description: string,
    destination: string,
    requiredRole: string | undefined
}

export interface NavigationSpacer {
    type: 'spacer',
    id: string
}

export interface NavigationElement {
    type: 'element',
    element: JSX.Element;
}

export type NavigationItem = NavigationEntry | NavigationSpacer | NavigationElement;

export interface NavigationProps {
    loginInformation: LoginInformation;
    items: NavigationItem[];
}

function renderEntry(entry: NavigationEntry, roles: string[], location: string) {
    if (entry.requiredRole && roles.indexOf(entry.requiredRole) < 0) {
        return null;
    }
    const isSelected = entry.destination === location;

    return (<li key={entry.destination} className={isSelected ? 'selected' : ''}>
        <Link to={entry.destination}>{entry.description}</Link>
    </li>);

}

function renderElement(element: NavigationElement) {
    return element.element;
}


function renderItem(item: NavigationItem, roles: string[], location: string) {
    switch (item.type) {
        case 'entry':
            return renderEntry(item, roles, location);
        case 'spacer':
            return <li key={item.id} className='spacer'/>;
        case 'element':
            return renderElement(item);
    }
}

export function Navigation(props: NavigationProps) {
    const location = useLocation();
    return <div className="navigation">
        <ul>
            {props.items.map(e => renderItem(e, props.loginInformation.roles, location.pathname))}
        </ul>
    </div>;

}
