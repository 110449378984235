import {HttpException} from "../exception";
import {getAuthorization} from "./LoginService";
import {ProfileDto} from "dto/ProfileDto";
import {params} from "../utils/Utils";


const ENDPOINT = "/api/v2/profile";

const profileCache: { [key: string]: ProfileDto } = {};

class ProfileService {

    async getProfileById(id: string): Promise<ProfileDto> {
        if (profileCache[id]) {
            return profileCache[id];
        }
        const response = await fetch(`${ENDPOINT}/${id}`, {
            headers: {...getAuthorization()},
            method: 'get'
        });

        if (!response.ok) {
            throw new HttpException(response);
        }

        const result = await response.json();
        profileCache[id] = result;
        return result as ProfileDto;

    }

    async getProfilesByParentOfCustomerId(customerId: string) {
        const response = await fetch(`${ENDPOINT}?${params({'parent-customer': customerId})}`, {
            headers: {...getAuthorization()},
            method: 'get'
        });

        if (!response.ok) {
            throw new HttpException(response);
        }

        const result = await response.json();
        return result as ProfileDto[];

    }

    async getProfilesByChildCustomerId(customerId: string) {
        const response = await fetch(`${ENDPOINT}?${params({'child-customer': customerId, 'generic': true})}`, {
            headers: {...getAuthorization()},
            method: 'get'
        });

        if (!response.ok) {
            throw new HttpException(response);
        }

        const result = await response.json();
        return result as ProfileDto[];

    }



    async getMyProfiles(): Promise<ProfileDto[]> {
        const response = await fetch(`${ENDPOINT}/mine`, {
            headers: {...getAuthorization()},
            method: 'get'
        });

        if (!response.ok) {
            throw new HttpException(response);
        }

        const result = await response.json();
        return result as ProfileDto[];

    }

    async getProfilesByUserId(userId: string): Promise<ProfileDto[]> {
        const response = await fetch(`${ENDPOINT}?${params({user: userId})}`, {
            headers: {...getAuthorization()},
            method: 'get'
        });

        if (!response.ok) {
            throw new HttpException(response);
        }

        const result = await response.json();
        return result as ProfileDto[];
    }

    async saveProfile(profile: ProfileDto): Promise<ProfileDto> {
        const response = await fetch(`${ENDPOINT}`, {
            headers: {...getAuthorization(), 'Content-Type': 'application/json'},
            method: "post",
            body: JSON.stringify(profile)

        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        const result = await response.json();
        return result;
    }

    async deleteProfile(profile: ProfileDto): Promise<void> {
        await fetch(`${ENDPOINT}/${profile.id}`, {
            headers: {...getAuthorization(), 'Content-Type': 'application/json'},
            method: "delete"
        })
    }
}

const instance = new ProfileService();


export {instance as ProfileService};
