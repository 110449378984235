import {ZonedDateTime} from '@js-joda/core';

import {HttpException} from "../exception";
import {LeafSensorData} from "../dbo/LeafSensorData";
import {WeatherData} from "../dbo/WeatherData";
import {getAuthorization} from "./LoginService";
import {request, requestOld} from "../utils/Utils";
import {PlantDto} from "../dto/PlantDto";


const ENDPOINT = "/api/v2/plant";

class PlantService {
    async getById(plantId: string): Promise<PlantDto> {
        const response = await fetch(`${ENDPOINT}/${plantId}`, {
            headers: {...getAuthorization()},
            method: 'get'
        });

        if (!response.ok) {
            throw new HttpException(response);
        }

        const result = await response.json();
        result.regDate = new Date(result.regDate);
        return result as PlantDto;
    }

    async getWeatherDataByPlantId(plantId: string, from: Date): Promise<WeatherData[]> {
        const response = await fetch(`${ENDPOINT}/${plantId}/weather?from=${from.toISOString()}`, {
            headers: {...getAuthorization()},
            method: 'get'
        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        let result = await response.json();
        for (let row of result) {
            // Change strings to date objects
            row.date = ZonedDateTime.parse(row.date);
        }
        return result;
    }

    async getPlantData(plantId: string, from: Date, to: Date): Promise<LeafSensorData[]> {
        const response = await fetch(`${ENDPOINT}/${plantId}/data?from=${from.toISOString()}&to=${to.toISOString()}`, {
            headers: {...getAuthorization()},
            method: "get"
        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        let result = await response.json();
        result = result.map((row: any) => {
            return {...row, timestamp: ZonedDateTime.parse(row.timestamp)};
        });

        return result as LeafSensorData[];
    }

    async savePlant(plant: PlantDto): Promise<PlantDto> {
        const response = await fetch(`${ENDPOINT}`, {
            headers: {...getAuthorization(), 'Content-Type': 'application/json'},
            method: "post",
            body: JSON.stringify(plant)
        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        const result = await response.json();
        result.regDate = new Date(result.regDate);
        return result;
    }

    async getLastDate(plantId: string): Promise<Date | null> {
        const response = await requestOld(`${ENDPOINT}/${plantId}/last-date`, {
            method: "get"
        });
        const result = await response.json();
        if (result) {
            return new Date(result);
        } else {
            return null;
        }
    }

    async movePlant(plantId: string, customerId: string): Promise<void> {
        await requestOld(`${ENDPOINT}/${plantId}/move/${customerId}`, {
            method: "post"
        });
        //await  response.json();
    }

    async getByCustomerId(customerId: string): Promise<PlantDto[]> {
        const result = await request<PlantDto[]>(`${ENDPOINT}/?customer=${customerId}`, );
        return result || [];
    }
    // async getById(plantId: string): Promise<PlantDto> {
    //     const url = new URL(`${ENDPOINT}`);
    //     url.searchParams.append('id', plantId);
    //     const response = await requestOld(url.toString() ,{
    //         method: 'get'
    //     });
    //
    //     const result = await response.json();
    //     return result;
    // }
}

const instance = new PlantService();


export {instance as PlantService};
