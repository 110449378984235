export interface RangeWidth {
    id: string;
    description: string;
    durationSec: number;
}

export const ALL_RANGE_WIDTHS: RangeWidth[] = [{
    id: "0",
    description: "1 Day",
    durationSec: 24 * 3600
}, {
    id: "1",
    description: "1 Week",
    durationSec: 7 * 24 * 3600
}, {
    id: "2",
    description: "2 Weeks",
    durationSec: 14 * 24 * 3600
}, {
    id: "3",
    description: "1 Month",
    durationSec: 30 * 24 * 3600
}, {
    id: "4",
    description: "6 Months",
    durationSec: 6 * 30 * 24 * 3600
}, {
    id: "5",
    description: "1 Year",
    durationSec: 365 * 24 * 3600
}];
