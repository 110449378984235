import {HttpException} from '../exception';
import {LoginInformation} from "../state";

const ENDPOINT: string = "/api/login";

export function getAuthorization() {
    const jwt = localStorage.jwt;
    return {
        "Authorization": `Bearer ${jwt}`
    };
}

export function getJwt() {
    return localStorage.jwt;
}

// function createForm(username: string, password: string, options: any) {
//     // const form = new FormData();
//     // form.append("username", username);
//     // form.append("password", password);
//     // return form;
//     let result = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
//     if (options.keepLoggedIn) {
//         result = result + `&keep-logged-in=true`;
//     }
//     return result;
// }

function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const result = JSON.parse(jsonPayload);
    return result;
}

let loginInformation : LoginInformation | undefined;

class LoginService {


    async login(username: string, password: string, options: any): Promise<LoginInformation> {
        console.log(`Performing login for user ${username}`);
        const response = await fetch(ENDPOINT, {
            body: JSON.stringify({username, password}),
            headers: {
                "Content-Type": "application/json"
            },
            method: "post"
        });
        if (!response.ok) {
            throw new HttpException(response);
        }
        const jwt = (await response.json()).id_token;
        const parsedJwt = parseJwt(jwt);
        localStorage.jwt = jwt;
        localStorage.username = username;
        localStorage.roles = JSON.stringify(parsedJwt.roles);
        localStorage.customerId = parsedJwt.customerId;
        loginInformation = new LoginInformation(username, jwt, parsedJwt.roles, parsedJwt.customerId);
        return loginInformation;
    }

    getLoginInformation(): LoginInformation {
        if (!loginInformation) {
            console.log("Login information not available, checking local storage");
            loginInformation = new LoginInformation(
                localStorage['username'],
                localStorage['jwt'],
                JSON.parse(localStorage.roles || '[]'),
                localStorage['customerId']
            );
        }
        return loginInformation;
    }

    isLoggedIn(): boolean {
        return localStorage['jwt'];
    }

    async logout() {
        loginInformation = undefined;
        delete localStorage.jwt;
        delete localStorage.username;
        delete localStorage.customerId;
        delete localStorage.roles;
    }

}

const instance = new LoginService();

export {instance as LoginService};

