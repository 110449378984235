import {HttpException} from "../exception";
import {getAuthorization, getJwt} from "./LoginService";
import {UserDto} from "dto/UserDto";

const ENDPOINT = "/api/v2/user"


export async function getUsers() {
    const jwt = getJwt();

    const response = await fetch(`${ENDPOINT}/`, {
        headers: {
            "Authorization": `Bearer ${jwt}`
        },
        method: "get"
    });

    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json();
    return result as UserDto[];


}

export async function saveUser(user: UserDto): Promise<UserDto> {
    const response = await fetch(`${ENDPOINT}/`,
        {
            method: 'post',
            headers: {
                ...getAuthorization(),
                'content-type': 'application/json'
            },

            body: JSON.stringify(user)
        });
    if (!response.ok) {
        throw new HttpException(response);
    }
    const result = await response.json();
    return result as UserDto;
}
