import React from 'react';
import {ZonedDateTime} from '@js-joda/core';
import './WeatherLine.css';
import {WeatherData} from "../dbo/WeatherData";


type WeatherLineProps = {
    data: WeatherData[],
    from: ZonedDateTime,
    to: ZonedDateTime
};

export function WeatherLine(props: WeatherLineProps) {
    const {data, from, to} = props;
    data.forEach(d => {
        (d as any).timestamp = d.date.toEpochSecond();
        (d as any).value = 1;
    });

    function renderWeatherIcon(data: WeatherData) {

        const position = (data.date.toEpochSecond() - from.toEpochSecond()) / (to.toEpochSecond() - from.toEpochSecond());
        const style: React.CSSProperties = {
            left: `calc(${position * 100}% - 12px)`
        };
        return <img alt={data.icon} key={data.id} className="weather-icon" src={data.icon} style={style}/>;
    }


    return <section>
        <h2>Weather Status</h2>
        <div className="weather-line">
            {data.map(renderWeatherIcon)}
        </div>
    </section>;
}