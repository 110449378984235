import React, {ChangeEvent, useState} from 'react';
import {DeviceDto} from "../../dto/DeviceDto";
import {TreeNode, TreeView} from "../TreeView";
import SideMenuEntry from "../../dbo/SideMenuEntry";
import {ALL_ITEMS_ID, makeDeviceId, makeFieldId} from "../../state/DashboardPageState";
import {RangeWidth} from "../../dbo/RangeWidth";
import {RangeSelector} from "../RangeSelector";
import './SideMenu.css';
import {DateService} from "../../service/DateService";
import {convert, LocalDate} from '@js-joda/core';
import {CustomerDto} from "../../dto/CustomerDto";
import {ProfileDto} from "../../dto/ProfileDto";
import {PlantDto} from "../../dto/PlantDto";
import {LoadingStatus} from "../../dbo/LoadingStatus";
import {Spinner} from "../Spinner";

export interface SideMenuProps {
    readonly onCustomerSelected: (customer: CustomerDto) => void;
    readonly customers: CustomerDto[];
    readonly selectedCustomerId: string;
    readonly selectedDate: Date,
    readonly ranges: RangeWidth[],
    readonly onRangeSelected: (rangeWidth: RangeWidth) => void,
    readonly devices: DeviceDto[],
    readonly selectedItems: { [key: string]: string },
    readonly onEntryToggled: (entryId: SideMenuEntry) => void,
    readonly selectedRangeId: string,
    readonly onDateSelected: (date: Date) => void;
    readonly profilesById: { [id: string]: ProfileDto };
    readonly plants: PlantDto[];
    readonly loadingStatus: LoadingStatus;
}


function mapDeviceToNode(device: DeviceDto, profile: ProfileDto, selectedItems: { [key: string]: string }): TreeNode<SideMenuEntry> {
    const children: TreeNode<SideMenuEntry>[] = [];
    if (profile) {
        for (let field of profile.sensors) {
            const id = makeFieldId(device, field);
            children.push({
                id,
                label: field.description,
                selected: !!selectedItems[id],
                children: [],
                payload: {device, field}
            })
        }
    }
    const deviceId = makeDeviceId(device);
    return {
        id: deviceId,
        label: device.description,
        selected: !!selectedItems[deviceId],
        children: children,
        payload: {device, field: null}
    }
}

export function SideMenu(props: SideMenuProps) {

    function dateChangeHandler(e: any) {
        const date = convert(LocalDate.parse(e.target.value)).toDate();
        props.onDateSelected(date);
    }

    const [filter, setFilter] = useState( '' );

    const trees = [] as any[];
    const filteredDevices = filter ? applyFilter(filter, props.devices ) : props.devices;
//    const filteredDevices = props.devices;

    if (props.plants.length === props.devices.length) {
        for (const device of filteredDevices) {
            const plant = device.plant as PlantDto;
            trees.push(mapDeviceToNode(device, props.profilesById[plant.deviceProfileId], props.selectedItems));
        }
    }
    const root: TreeNode<SideMenuEntry> = {
        children: trees,
        id: ALL_ITEMS_ID,
        label: 'All Devices',
        selected: props.selectedItems[ALL_ITEMS_ID] !== undefined,
        payload: {device: null, field: null}
    };


    function applyFilter(text: string, devices: DeviceDto[]) : DeviceDto[] {
        console.log(`Apply filter ${text}`);
        return devices.filter( d => {
            return d.description.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });
    }

    function onFilterChanged(event: ChangeEvent<HTMLInputElement>) {
        setFilter( event.target.value );
    }


    switch (props.loadingStatus) {
        case "resolved":
            return <div className="side-menu">

                <RangeSelector ranges={props.ranges} selectedRangeId={props.selectedRangeId}
                               onRangeSelected={props.onRangeSelected}/>
                <input type='date' value={DateService.formatDate(props.selectedDate)}
                       onChange={dateChangeHandler}/>
                <input placeholder='Filter devices...' id='tree-device-filter' type='text'
                       onChange={onFilterChanged}></input>

                <TreeView node={root} onClick={node => props.onEntryToggled(node.payload)}/>
            </div>;
        case "pending":
            return <Spinner/>
        case "rejected":
            return <div>Cannot load data!</div>;
    }

}
