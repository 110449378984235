import './Menu.css';
import React from 'react';
import {RangeWidth} from "../../dbo/RangeWidth";
import {RangeSelector} from "../RangeSelector";
import {convert, LocalDate} from '@js-joda/core';
import {DateService} from "../../service/DateService";

interface MenuProps {
    ranges: RangeWidth[],
    selectedDate: Date | null,
    selectedRangeId: string,
    filterLuminosity: boolean,
    onDateSelected: (date: Date) => void,
    onFilterLuminosityToggled: (value: boolean) => void,
    onRangeSelected: (interval: RangeWidth) => void
}

export function Menu(props: MenuProps) {
    function dateChangeHandler(e: any) {
        const date = convert(LocalDate.parse(e.target.value)).toDate();
        props.onDateSelected(date);
    }

    function filterLuminosityChangeHandler(e: any) {
        const value = Boolean(e.target.value);
        console.debug("Changing luminosity filter to", value);

        props.onFilterLuminosityToggled(value);
    }

    return <div className='option-menu'>
        {props.selectedDate === null ? null :
            <span>
                <span>Filter luminosity: <input type='checkbox'  checked={props.filterLuminosity} onChange={filterLuminosityChangeHandler} /></span>
                <span>Date:</span><input type='date' value={DateService.formatDate(props.selectedDate)}
                                         onChange={dateChangeHandler}/>
                <span>Charts width:</span><RangeSelector ranges={props.ranges} onRangeSelected={props.onRangeSelected}
                                                     selectedRangeId={props.selectedRangeId}/>
                                                 </span>}
    </div>;
}
