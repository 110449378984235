import {LeafSensorData} from "./LeafSensorData";
import {ProfileDto} from "dto/ProfileDto";

export type DeviceChartData = { [key: string]: ChartDataElement[] | undefined };


export type ChartDataElement = {
    timestamp: number,
    value: number
}

function mean(values: number[]) {
    if (values.length === 0) {
        return 0;
    }
    let result = 0;
    for (let v of values) {
        result += v;
    }
    return result / values.length;
}


function smooth(dataset: any[], key: string, nElements: number): ChartDataElement[] {
    let result: ChartDataElement[] = [];
    let lastValues = [];
    for (let row of dataset) {
        const value = row[key];
        if (value === undefined || value === null) {
            continue;
        }
        lastValues.push(value);
        if (lastValues.length > nElements) {
            lastValues.shift();
        }
        const item = {
            value: mean(lastValues),
            timestamp: row.timestamp.toEpochSecond()
        };
        result.push(item);
    }
    return result;
}


export function makeChartDataForLeafSensor(data: LeafSensorData[], profile: ProfileDto, filterLuminosity: boolean): DeviceChartData {
    if (filterLuminosity) {
        data = data.filter(d => d.lux <= 10);
    }
    let result: { [key: string]: ChartDataElement[] } = {};
    for (const sensor of profile.sensors) {
        result[sensor.id] = smooth(data, sensor.id, 5);

    }
    return result;
}
